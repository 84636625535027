import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { colors, mobileThresholdPixels, Title1 as StyledTitle1 } from '../home/v3/styledComponents';

const Title = styled(StyledTitle1)`
  margin-bottom: 20px;
`;

const SubtitleContainer = styled.div`
  margin-bottom: 50px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const Subtitle = styled.h2`
  font-family: Roboto;
  font-weight: normal;
  color: ${colors.navy};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0px auto;
  max-width: 832px;
`;

const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
  width: 900px;
  margin-bottom: 60px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0px;
  }
`;

const NavItem = styled(Link)`
  display: inline;
  margin: 0px 23.5px;
  cursor: pointer;
  color: ${colors.darkGrey2};
  font-family: Libre Baskerville;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  display: inline-block;
  text-align:center;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 10px;
  }
`;

const NavItemText = styled.div`
  ${({ isSelected }) => isSelected && `
    color: ${colors.navy};
    text-decoration: underline;
  `}
`;

const LaRubriqueHeader = ({ categories, category }) => (
  <>
    <Title>Les inspirations</Title>
    <SubtitleContainer>
      <Subtitle>
        Inspirations upcycling, conseils couture, astuces réparation et personnalisation :
        retrouvez ici l’ensemble de nos articles pour réinventer votre dressing et donner une seconde vie
        à vos vêtements, accessoires et linge de maison.
      </Subtitle>
    </SubtitleContainer>
    <NavBar>
      {categories.map(({ slug, name, path }) => (
        <NavItem key={slug} to={path}>
          <NavItemText isSelected={category?.name === name}>{name}</NavItemText>
        </NavItem>
      ))}
    </NavBar>
  </>
);

LaRubriqueHeader.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
  }),
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

LaRubriqueHeader.defaultProps = {
  category: undefined,
};

export default LaRubriqueHeader;
