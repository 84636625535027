import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useLocation } from '@reach/router';

import { Container, Content as StyledContent } from '../components/home/homeStyledComponents';
import { contentWidth, margins, mobileThresholdPixels } from '../components/home/v3/styledComponents';
import MenuBar from '../components/home/MenuBar';
import LaRubriqueHeader from '../components/LaRubrique/LaRubriqueHeader';
import ListPost from '../components/LaRubrique/ListPost';
import Pagination from '../components/LaRubrique/Pagination';
import Footer from '../components/home/v3/Footer';
import Layout from '../layouts/index';
import routesMap from '../Routes';

const Content = styled(StyledContent)`
  margin-bottom: ${margins.xl};
  
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 22px;
    box-sizing: border-box;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
  width: ${contentWidth - 296}px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: unset;
  }
`;

const CategoryArticlesTemplate = ({ pageContext }) => {
  const {
    posts, categories, category, pageCount,
  } = pageContext;
  const { pathname } = useLocation();
  const indexFromLocation = pathname.match(new RegExp('[^/]+(?=/$|$)'));
  const activePage = indexFromLocation ? +indexFromLocation[0] : 1;

  const onPageChange = (index) => {
    navigate(routesMap.getCategoryArticlePageUrl(category?.slug, index));
  };

  return (
    <Layout
      routeSlug={(category?.slug) ? `LaRubriqueCategory_${category.slug}` : 'LaRubrique'}
      routeUrl={routesMap.getCategoryArticlePageUrl(category?.slug, activePage)}
    >
      <Container>
        <MenuBar />
        <Content>
          <LaRubriqueHeader category={category} categories={categories} />
          <SubContainer>
            {posts.map((post) => (
              <ListPost key={post.slug} post={post} />
            ))}
          </SubContainer>
          <SubContainer>
            <Pagination activePage={activePage} pageCount={pageCount} onPageChange={onPageChange} />
          </SubContainer>
        </Content>
        <Footer />
      </Container>
    </Layout>
  );
};

CategoryArticlesTemplate.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    category: PropTypes.shape({
      slug: PropTypes.string,
    }),
    categories: PropTypes.arrayOf(PropTypes.shape({})),
    pageCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default CategoryArticlesTemplate;
