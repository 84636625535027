import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { margins, colors, fontSizes } from '../home/v3/styledComponents';

const getVisiblePages = (page, pageCount) => {
  let middleIndex = page;

  if (page === 1) {
    middleIndex = page + 1;
  } else if (page === pageCount) {
    middleIndex = page - 1;
  }
  return [middleIndex - 1, middleIndex, middleIndex + 1].filter((index) => index && index <= pageCount);
};

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  list-style-type: none;
  font-family: Libre Baskerville;
  font-size: ${fontSizes.l};
  color: ${colors.darkGrey2};
`;

const PageNumber = styled.span`
  cursor: pointer;
  margin: 0px ${margins.xs};
  user-select: none;
  min-width: 25px;
  ${({ active }) => active && `
    margin-bottom: -2px;
    color: ${colors.navy};
    font-size: ${fontSizes.xl};
    min-width: 32px;
  `}
  ${({ hide }) => hide && `
    opacity: 0;
    cursor: initial;
  `}
`;

const Break = styled(PageNumber)``;

const Controller = styled(PageNumber).attrs({ active: true })`
  font-size: ${fontSizes.l};
`;

const Pagination = ({ activePage, pageCount, onPageChange }) => {
  const isFirstPage = activePage === 1;
  const isLastPage = activePage === pageCount;
  const hidePrevBreak = activePage <= 2 || pageCount <= 3;
  const hideNextBreak = activePage > pageCount - 2 || pageCount <= 3;
  const visiblePageNumbers = getVisiblePages(activePage, pageCount);

  const goPrev = () => onPageChange(isFirstPage ? activePage : activePage - 1);
  const goNext = () => onPageChange(isLastPage ? activePage : activePage + 1);

  return (
    <Container>
      <Controller hide={isFirstPage} onClick={goPrev}>&larr;</Controller>
      <Break hide={hidePrevBreak} onClick={() => onPageChange(1)}>...</Break>
      {visiblePageNumbers.map((number) => (
        <PageNumber key={number} active={number === activePage} onClick={() => onPageChange(number)}>
          {number}
        </PageNumber>
      ))}
      <Break hide={hideNextBreak} onClick={() => onPageChange(pageCount)}>...</Break>
      <Controller hide={isLastPage} onClick={goNext}>&rarr;</Controller>
    </Container>
  );
};

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
