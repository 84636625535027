import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Image, Transformation, Placeholder } from 'cloudinary-react';

import withAppContext from '../../withAppContext';
import { colors, mobileThresholdPixels } from '../home/v3/styledComponents';
import { categories, subCategories, getDateSincePost } from './listPostInfos';
import Cloudinary from '../Cloudinary';

const Content = styled.div`
  display: flex;
  position: relative;
  width: 404px;
  background: ${colors.white};
  margin-bottom: 77px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px auto;
    width: 100%;
  }
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 210px;
  overflow: hidden;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 143px;
  }
`;

const SubImgContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: transform .5s;
`;

const MobileImage = styled(Image)`
  width: 100%;
  height: 143px;
  object-fit: cover;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  overflow: hidden;

  :hover ${SubImgContainer} {
    transform: scale(1.1);
  }
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  color: ${colors.navy};
  background-color: ${colors.white};
`;

const CategoryText = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: ${colors.green};
  margin: 15px 0px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 12px Opx;
  }
`;

const Title = styled.span`
  color: ${colors.navy};
  font-family: Libre Baskerville;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 15px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 12px;
  }
`;

const Text = styled.span`
  width: 100%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 39px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 10px;
  }
`;

const Date = styled.span`
  font-size: 10px;
  color: ${colors.darkGrey2};
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

const getUnformattedText = (text) => (
  text.replace(/<yellow>/gi, '').replace(/<bold>/gi, '').replace(/\n/gi, ' ').replace(/ {2}/gi, ' ')
    .replace(/<link>/gi, '')
);

const ListPost = ({ post, context: { isMobile } }) => {
  let intro = '';
  let title = post.mainTitle;
  if (!post.template) {
    intro = post.row1Text;
    title = post.topImageText;
  } else {
    intro = post.template === 3 ? (post.introduction || post.seo.description) : post.topBlock.row1.text.text;
    title = post.template === 3 ? post.mainTitle : (post.topBlock.title.text || post.topBlock.title.desktop.text);
  }

  return (
    <Content>
      <StyledLink to={post.path}>
        <ImgContainer>
          <SubImgContainer>
            <Cloudinary>
              {isMobile
                ? (
                  <MobileImage responsive width="auto" publicId={post.listImage?.relativePath} alt={post.listImageAlt}>
                    <Placeholder type="pixelate" />
                    <Transformation quality="auto:best" fetchFormat="auto" />
                    <Transformation effect="unsharp_mask" />
                    <Transformation gravity="center" width="auto:1" height="143" crop="fill" dpr="auto" />
                    <Transformation gravity="center" />
                  </MobileImage>
                )
                : (
                  <Image publicId={post.listImage?.relativePath} alt={post.listImageAlt}>
                    <Placeholder type="pixelate" />
                    <Transformation quality="auto:best" fetchFormat="auto" />
                    <Transformation effect="unsharp_mask" />
                    <Transformation gravity="center" width="404" height="210" crop="fill" dpr="auto" />
                  </Image>
                )}
            </Cloudinary>
          </SubImgContainer>
        </ImgContainer>
        <Overlay>
          <CategoryText>
            {categories[post.typeOfWork]}
            {' '}
            |
            {' '}
            {subCategories[post.categoryOrCloth]}
          </CategoryText>
          <Title>{getUnformattedText(title)}</Title>
          {intro && <Text>{getUnformattedText(intro)}</Text>}
          <Date>
            Posté il y a
            {' '}
            {getDateSincePost(post.datePublished)}
          </Date>
        </Overlay>
      </StyledLink>
    </Content>
  );
};

ListPost.propTypes = {
  post: PropTypes.shape({
    mainTitle: PropTypes.string,
    template: PropTypes.number,
    row1Text: PropTypes.string,
    topImageText: PropTypes.string,
    seo: PropTypes.shape({
      description: PropTypes.string,
    }),
    topBlock: PropTypes.shape({
      title: PropTypes.shape({
        text: PropTypes.string,
        desktop: PropTypes.shape({
          text: PropTypes.string,
        }),
      }),
      row1: PropTypes.shape({
        text: PropTypes.shape({
          text: PropTypes.string,
        }),
      }),
    }),
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    listImageAlt: PropTypes.string.isRequired,
    listImage: PropTypes.shape({
      relativePath: PropTypes.string,
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    introduction: PropTypes.string,
    typeOfWork: PropTypes.string,
    categoryOrCloth: PropTypes.string,
    datePublished: PropTypes.string,
  }).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(ListPost);
