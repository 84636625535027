// cSpell:ignore vetements, reparer, mariee, matiere, recyclee, creez

import moment from 'moment-timezone';

const daysDiff = (postDate) => moment().diff(postDate, 'days');
const monthsDiff = (postDate) => moment().diff(postDate, 'months');
const getDateSincePost = (postDate) => (monthsDiff(postDate) >= 1) ? `${monthsDiff(postDate)} mois` : `${daysDiff(postDate)} jours`;

const categories = {
  RETOUCHE: 'Retouche',
  TRANSFORMATION: 'Upcycling',
  PERSONNALISATION: 'Personnalisation',
  CREATION: 'Création',
};

const subCategories = {
  pant: 'Pantalon',
  haut: 'Haut',
  chem: 'Chemise',
  robe: 'Robe',
  mari: 'Robe de mariée',
  mant: 'Manteau & veste',
  tail: 'Costume',
  jupe: 'Jupe',
  pull: 'Pull & gilet',
  ling: 'Lingerie & maillot',
  mais: 'Linge de maison',
  deco: 'Décoration',
  acce: 'Accessoires',
  dive: 'Divers',
};

const postSlugs = {
  NO_CATEGORY: {
    front: 'ServiceDeRetoucheDeVetements',
    mostSeen: [
      'RetoucheDesOurletsSurVosRideaux', 'CommentReparerUnTrouDeMiteDansUnPull',
      'LesRetouchesDuCostume', 'RetoucheDesOurletsDePantalon', 'RetoucheDuDosNuSurLaRobeDeMariee'],
  },
  RETOUCHE: {
    front: 'CommentRetoucherSaRobeDeMariee',
    mostSeen: [
      'RetoucheDesOurletsSurVosRideaux', 'CommentReparerUnTrouDeMiteDansUnPull',
      'LesRetouchesDuCostume', 'RetoucheDesOurletsDePantalon', 'RetoucheDuDosNuSurLaRobeDeMariee'],
  },
  TRANSFORMATION: {
    front: 'TransformerUnChemisierTropLarge',
    mostSeen: [
      'LUpcyclingQuEstCeQueCEst', 'CommentTransformerUneRobeEnCombishort',
      'CommentTransformerSaRobeDeMariee', 'CommentTransformerUneJupeEnShortCouture', 'UpcyclingRobeDHiver'],
  },
  PERSONNALISATION: {
    front: 'BroderiesChemises',
    mostSeen: [
      'BrillezAvecSwarovski', 'BroderiesJean', 'MaillotDeBain', 'BroderiesCulotte', 'BroderieLingeDeMaison',
    ],
  },
  CREATION: {
    front: 'SacAMainEnMatiereRecyclee',
    mostSeen: [
      'BalzacXTilli', 'CreezVosPropresAccessoires', 'FabriquezVotreSacEnTissuRecycle'],
  },
};

export {
  categories,
  subCategories,
  postSlugs,
  getDateSincePost,
};
